import React from 'react';

// Generic component to add structured data to any page
const StructuredData = ({ data }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  );
};

// Helper function to generate Event schema for a single event
export const generateEventSchema = (event) => {
  const {
    name,
    description,
    startDate,
    endDate,
    location,
    image,
    url,
    organizer,
    category
  } = event;

  return {
    "@context": "https://schema.org",
    "@type": "Event",
    name,
    description,
    startDate,
    endDate,
    location: {
      "@type": "Place",
      name: location.name,
      address: {
        "@type": "PostalAddress",
        addressLocality: "Ghent",
        addressRegion: "East Flanders",
        addressCountry: "BE",
        streetAddress: location.address || "",
      },
    },
    image: image || "https://ghentevents.be/drawings-white/mannetjes5.svg",
    url: url || `https://ghentevents.be/event/${encodeURIComponent(name.toLowerCase().replace(/\s+/g, '-'))}`,
    organizer: {
      "@type": "Organization",
      name: organizer,
    },
    eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
    eventStatus: "https://schema.org/EventScheduled",
    performer: {
      "@type": "PerformingGroup",
      name: organizer,
    },
    inLanguage: ["en", "nl"],
    typicalAgeRange: "18-50",
    category: category,
    audience: {
      "@type": "Audience",
      audienceType: "Young adults and professionals who love art and culture"
    }
  };
};

// Helper function to generate a list of events schema
export const generateEventsListSchema = (events) => {
  return {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: events.map((event, index) => ({
      "@type": "ListItem",
      position: index + 1,
      item: generateEventSchema(event),
    })),
  };
};

// Helper function to generate WebSite schema
export const generateWebsiteSchema = () => {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "Ghent Events | Gent Evenementen",
    alternateName: ["Ghent Events", "Gent Evenementen", "Events in Ghent", "Evenementen in Gent"],
    url: "https://ghentevents.be",
    potentialAction: {
      "@type": "SearchAction",
      target: {
        "@type": "EntryPoint",
        urlTemplate: "https://ghentevents.be/search?q={search_term_string}"
      },
      "query-input": "required name=search_term_string"
    },
    description: "The complete guide to all events in Ghent, Belgium. Discover concerts, theater, comedy, academic talks, sports & cultural events for people aged 18-50 who love art and culture.",
    inLanguage: ["en", "nl"],
    audience: {
      "@type": "Audience",
      audienceType: "People aged 18-50 who live in Ghent and enjoy art, culture, and active lifestyles"
    },
    publisher: {
      "@type": "Organization",
      name: "Ghent Events",
      logo: {
        "@type": "ImageObject",
        url: "https://ghentevents.be/drawings-white/mannetjes5.svg"
      }
    }
  };
};

// Helper function to generate a local business schema for Ghent Events
export const generateLocalBusinessSchema = () => {
  return {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Ghent Events | Gent Evenementen",
    image: "https://ghentevents.be/drawings-white/mannetjes5.svg",
    url: "https://ghentevents.be",
    telephone: "",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Ghent",
      addressRegion: "East Flanders",
      addressCountry: "Belgium",
      postalCode: "9000"
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 51.0543,
      longitude: 3.7174,
    },
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      opens: "00:00",
      closes: "23:59",
    },
    priceRange: "Free",
    description: "Find the best events in Ghent for art lovers, cultural enthusiasts, and active young adults and professionals. Events updated daily.",
    mainEntityOfPage: "https://ghentevents.be",
    sameAs: [
      "https://www.facebook.com/ghentevents",
      "https://www.instagram.com/ghentevents.be"
    ],
    keywords: "Ghent events, Gent evenementen, events in Ghent, uitgaan in Gent",
    slogan: "Your #1 guide to all events in Ghent for the culturally curious",
    areaServed: {
      "@type": "City",
      name: "Ghent",
      alternateName: "Gent"
    }
  };
};

// New helper function to generate a FAQ schema
export const generateFAQSchema = () => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What types of events can I find on Ghent Events?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Ghent Events features a comprehensive list of events happening in Ghent, including concerts, theater performances, comedy shows, art exhibitions, academic talks, sports events, and cultural activities. We focus on events that appeal to young adults and professionals aged 18-50 who love art, culture, and living life to the fullest."
        }
      },
      {
        "@type": "Question",
        name: "Is Ghent Events available in Dutch?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, Ghent Events is fully available in both English and Dutch. You can switch between languages using the language switcher at the top of the page."
        }
      },
      {
        "@type": "Question",
        name: "How often is Ghent Events updated?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Our event listings are updated daily to ensure you have access to the most current information about events happening in Ghent."
        }
      }
    ]
  };
};

export default StructuredData; 